<template>
    <div>
          <!-- <div v-for="(item,index) in list" :key="index">
            <span style="display:block;height: 1rem;">车辆影像</span>
            <van-uploader v-model="item.img_box" multiple   :max-count="4" :after-read="afterRead" accept="image/*" id="imgBox" />
        </div>
        <button @click="btn()">1111</button> -->

         <div class="ver-code-bottom-one-right-code">
            <div class="posting-uploader-item" v-for="(item,index) in list" :key="index">
                <img :src="item"  alt="图片" class="imgPreview">
                <van-icon name="close" @click="delImg(index)" class="delte"/>
            </div>
            <van-uploader  :after-read="afterZRead"  v-show="list.length<=8" />
        </div>
    </div>
</template>
<script>
export default {
    data(){
            return{
                list: [
                        // {img_box: []},
                        // {img_box: []},
                        // {img_box: []},
                        // {img_box: []},
                    ], //
                imgs:[],
                ids:null
            }
    },
    methods:{
        afterRead(file,detail) {
        
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                params.append("w", 200);
                params.append("h", 200);
                this.$http.post("/index.php/index/upload", params).then((res) => {
                    if (res.data.code == 0) {
                        //console.log(res.data.data)
                        this.imgs.push(res.data.data)
                        //console.log(this.imgs)
                        
                        //console.log(detail.index)
                        
                        this.list.forEach(a=>{
                            a.img_box = this.imgs
                        })
                        

                    }
      });

    },
  
  afterZRead(file) {
  
    //console.log(this.list);

     let params = new FormData(); //创建form对象
                params.append("file", file.file);
                params.append("w", 200);
                params.append("h", 200);
    this.$http.post("/index.php/index/upload", params).then(res =>{

      if(res.data.code == 0){
        //console.log(res);
        
        this.list.push(res.data.data); //上传一张之后压入这个数组
        for(var i=0;i<this.list.length;i++){
          this.list[i]= changeImgUrl(this.list[i])
        }
        //console.log(this.list);
        this.mod_pic_list.push(JSON.parse(JSON.stringify(res.data))); //深克隆数据列表会是元数据
        //console.log(this.mod_pic_list);
        // this.mod_id_card_z = res.data;
        Toast("上传图片成功")

      }else{
        Toast(res.msg)
      }

    })
  },
    
    btn(){
        //console.log(this.list)
    }
    }
}
</script>